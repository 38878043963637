import React, { useRef, useState, useEffect, useContext } from "react";
import emailjs from "@emailjs/browser";

// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Text } from "../components/languages/Text/Text";

import { languageOptions } from "../components/languages";
import {
  LanguageContext,
  LanguageProvider,
} from "../components/container/Language";

function Home() {
  const emailRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();

  const [loading, setLoading] = useState(false);

  const [dropdownShow, setDropdownShow] = useState(false);

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  useEffect(() => {
    emailjs.init("k5IrluD-7a4J33Pet");
  }, []);

  const handleDropdown = () => {
    setDropdownShow(!dropdownShow);
  };

  const handleLangSelect = (lang) => {
    var langSimple = "";
    if (lang == "English") {
      langSimple = "en";
    } else if (lang == "German") {
      langSimple = "ge";
    }
    setDropdownShow(false);
    userLanguageChange(langSimple);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_ld9gf11";
    const templateId = "template_n4gbhbp";
    console.log({
      from_name: nameRef.current.value,
      from_email: emailRef.current.value,
      from_phone: phoneRef.current.value,
      from_subject: subjectRef.current.value,
      from_message: messageRef.current.value,
    });
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        from_name: nameRef.current.value,
        from_email: emailRef.current.value,
        from_phone: phoneRef.current.value,
        from_subject: subjectRef.current.value,
        from_message: messageRef.current.value,
      });
      alert("Successfully sent!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="preloader d-none">
        <div className="loader">
          <div className="ytp-spinner">
            <div className="ytp-spinner-container">
              <div className="ytp-spinner-rotator">
                <div className="ytp-spinner-left">
                  <div className="ytp-spinner-circle"></div>
                </div>
                <div className="ytp-spinner-right">
                  <div className="ytp-spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="index.html">
                  <p className="a-navlogo">CryptoPlan</p>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="page-scroll active" href="#home">
                        <Text tid={"home"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">
                        <Text tid={"about"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#service">
                        <Text tid={"partner"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#roadmap">
                        <Text tid={"roadmap"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#team">
                        <Text tid={"team"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#contact">
                        <Text tid={"contact"}></Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="a-position-relative">
                  <p
                    className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    data-dropdown-trigger="hover"
                    onClick={() => {
                      handleDropdown();
                    }}
                  >
                    {userLanguage == "en" ? "English" : "German"}
                    <svg
                      className="w-2.5 h-2.5 ml-2.5 a-svg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </p>
                  <div className="relative"></div>
                  {dropdownShow && (
                    <div
                      id="dropdown"
                      className="z-10 block a-dropdown bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black"
                            onClick={() => {
                              handleLangSelect("English");
                            }}
                          >
                            <Text tid={"en"} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black"
                            onClick={() => {
                              handleLangSelect("German");
                            }}
                          >
                            <Text tid={"ge"} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section
        id="home"
        className="hero-section"
        style={{ width: "100%", overflow: "hidden" }}
      >
        <div className="shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7">
              <div className="hero-content-wrapper">
                <h1 className="text-white wow fadeInDown" data-wow-delay=".2s">
                  <Text tid={"header-intro"}></Text>
                </h1>
                <p className="text-white wow fadeInLeft" data-wow-delay=".4s">
                  <Text tid={"header-description"}></Text>
                </p>
                <a
                  href="#"
                  className="theme-btn wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <Text tid={"register"}></Text>
                </a>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="hero-img">
                <img
                  src="img/hero-img.svg"
                  alt=""
                  className="wow fadeInRight"
                  data-wow-delay=".5s"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="feature" className="feature-section pt-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 mx-auto">
              <div className="section-title text-center mb-55">
                <h2 className="mb-20 wow fadeInUp" data-wow-delay=".2s">
                  <Text tid={"solution"}></Text>
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  <Text tid={"solutionDetail"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="features-wrapper">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="single-feature text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="feature-img mb-20">
                    <img src="img/feature-1.svg" alt="" />
                  </div>
                  <div className="feature-content">
                    <h5>
                      <Text tid={"solution1"}></Text>
                    </h5>
                    <p>
                      <Text tid={"solution1-desc"}></Text>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="single-feature text-center mb-30 wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <div className="feature-img mb-25">
                    <img src="img/feature-2.svg" alt="" />
                  </div>
                  <div className="feature-content">
                    <h5>
                      <Text tid={"solution2"}></Text>
                    </h5>
                    <p>
                      <Text tid={"solution2-desc"}></Text>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="single-feature text-center mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="feature-img mb-20">
                    <img src="img/feature-3.svg" alt="" />
                  </div>
                  <div className="feature-content">
                    <h5>
                      <Text tid={"solution3"}></Text>
                    </h5>
                    <p>
                      <Text tid={"solution3-desc"}></Text>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="single-feature text-center mb-30 wow fadeInUp"
                  data-wow-delay=".7s"
                >
                  <div className="feature-img mb-20">
                    <img src="img/feature-1.svg" alt="" />
                  </div>
                  <div className="feature-content">
                    <h5>
                      <Text tid={"solution4"}></Text>
                    </h5>
                    <p>
                      <Text tid={"solution4-desc"}></Text>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-section pt-150 pb-20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-img mb-30">
                <img
                  src="img/about-img.svg"
                  alt="image"
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-content mb-30">
                <div className="section-title mb-40">
                  <h2 className="wow fadeInUp" data-wow-delay=".2s">
                    <Text tid={"aboutUs"}></Text>
                  </h2>
                </div>
                <p className="mb-15 wow fadeInUp" data-wow-delay=".4s">
                  <Text tid={"about1"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about2"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about3"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about4"}></Text>
                </p>
                <a
                  href="#"
                  className="theme-btn theme-btn-2 wow fadeInRight"
                  data-wow-delay=".8s"
                >
                  <Text tid={"learnmore"}></Text>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="service-section pt-150 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-10 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="mb-20 wow fadeInDown"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"partner-with-us"}></Text>
                </h2>
                <p
                  className="wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"partner-desc"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-money-protection"></i>
                </div>
                <h4>
                  <Text tid={"partner1"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-alarm"></i>
                </div>
                <h4>
                  <Text tid={"partner2"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-reload"></i>
                </div>
                <h4>
                  <Text tid={"partner3"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-cog"></i>
                </div>
                <h4>
                  <Text tid={"partner4"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-bolt-alt"></i>
                </div>
                <h4>
                  <Text tid={"partner5"}></Text>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="roadmap"
        className="roadmap-section pt-100 pb-70 img-bg"
        style={{ backgroundImage: "url('img/common-bg.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="text-white mb-20 wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"roadmap"}></Text>
                </h2>
                <p
                  className="text-white wow fadeInUp"
                  data-wow-delay=".6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"roadmap-desc"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="roadmap-wrapper justify-content-center">
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap1-when"}></Text>
              </span>
              <p>
                <Text tid={"roadmap1-content"}></Text>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap2-when"}></Text>
              </span>
              <p>
                <Text tid={"roadmap2-content"}></Text>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap3-when"}></Text>
              </span>
              <p>
                <Text tid={"roadmap3-content"}></Text>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap4-when"}></Text>
              </span>
              <p>
                <Text tid={"roadmap4-content"}></Text>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap5-when"}></Text>
              </span>
              <p>
                <Text tid={"roadmap5-content"}></Text>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="team-section pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="mb-20 wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"meetTeam"}></Text>
                </h2>
                <p
                  className="mb-55 wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"meetTeamDesc"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-1.png" alt="" />
                  <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-info">
                    <h5>
                      <Text tid={"team1-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team1-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-2.png" alt="" />
                  <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-info">
                    <h5>
                      <Text tid={"team2-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team2-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-3.png" alt="" />
                  <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-info">
                    <h5>
                      <Text tid={"team3-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team3-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-4.png" alt="" />
                  <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-info">
                    <h5>
                      <Text tid={"team4-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team4-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="countdown-section pt-70 pb-70 img-bg"
        style={{ backgroundImage: "url('img/common-bg.jpg')" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="countdown-img">
                <img
                  src="img/countdown-img.svg"
                  alt=""
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="countdown-content-wrapper">
                <div className="countdown" style={{ display: "none" }}>
                  <ul id="countdown-example">
                    <li>
                      <span className="days">89</span>
                      <p className="days_text">DAYS</p>
                    </li>
                    <li>
                      <span className="hours">53</span>
                      <p className="hours_text">HOUR</p>
                    </li>
                    <li>
                      <span className="minutes">34</span>
                      <p className="minutes_text">MINS</p>
                    </li>
                    <li>
                      <span className="seconds">08</span>
                      <p className="seconds_text">SECS</p>
                    </li>
                  </ul>
                </div>
                <div className="countdown-content">
                  <h2
                    className="text-white mb-35 wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <Text tid={"howWorks"}></Text>
                  </h2>
                  <p
                    className="text-white mb-30 wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <Text tid={"howWorksDesc"}></Text>
                  </p>
                  <video
                    width="100%"
                    height="380"
                    controls
                    className="mt-4 m-auto"
                  >
                    <source src="video/bg.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-section pt-120 pb-105">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xl-6 col-lg-7">
              <div className="contact-wrapper mb-30">
                <h2 className="mb-20 wow fadeInDown" data-wow-delay=".2s">
                  <Text tid={"contactUs"}></Text>
                </h2>
                <p className="mb-55 wow fadeInUp" data-wow-delay=".4s">
                  <Text tid={"contactdesc"}></Text>
                </p>
                <form
                  method="POST"
                  className="contact-form"
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        ref={nameRef}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        ref={emailRef}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        ref={phoneRef}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        ref={subjectRef}
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        name="message"
                        id="message"
                        rows="5"
                        placeholder="Message"
                        ref={messageRef}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="theme-btn theme-btn-2"
                    disabled={loading}
                  >
                    <Text tid={"sendMessage"}></Text>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <div className="contact-map mb-30">
                <div className="map-canvas">
                  <iframe
                    className="map"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Mission%20District%2C%20San%20Francisco%2C%20CA%2C%20USA&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer
        className="footer pt-100 img-bg"
        style={{ backgroundImage: "url('img/common-bg.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <a href="index.html" className="logo mb-40">
                  <p className="a-navlogo">
                    <Text tid={"projectName"}></Text>
                  </p>
                </a>
                <p className="mb-30">
                  <Text tid={"header-intro"}></Text>.
                </p>
                <div className="footer-social-links">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="lni lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-twitter-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-linkedin-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni lni-instagram-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInUp"
                data-wow-delay=".4s"
              >
                {/* <h4>Company</h4>
                <ul className="footer-links">
                  <li>
                    <a href="javascript:void(0)">Home</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">About</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Partner</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Team</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Contact</a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInUp"
                data-wow-delay=".6s"
              >
                {/* <h4>Resource</h4>
                <ul className="footer-links">
                  <li>
                    <a href="javascript:void(0)">Documentation</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">IOS & Android Apps</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Support Forum</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Terms Conditions</a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInRight"
                data-wow-delay=".8s"
              >
                <h4>
                  <Text tid={"contact"}></Text>
                </h4>
                <ul>
                  <li>
                    <p>
                      <br />
                      <Text tid={"contactInfo"}></Text>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p className="mb-0 text-white text-center">
              @ 2023 &nbsp;
              <a href="https://uideck.com" rel="nofollow" target="_blank">
                <Text tid={"projectName"}></Text>
              </a>
            </p>
          </div>
        </div>
      </footer>

      <a href="#" className="scroll-top">
        <i className="lni lni-chevron-up"></i>
      </a>
    </div>
  );
}

export default Home;
