import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./views/Home";

import { LanguageProvider } from "./components/container/Language";

import { Text } from "./components/languages/Text/Text";

function App() {
  return (
    <div>
      <Router>
        <LanguageProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            </Routes>
        </LanguageProvider>
      </Router>
    </div>
  );
}

export default App;
